<template>
  <div class="bg-main-color height-fill">
    <div class="container-pa16">
      <br /><br /><br /><br />
      <div class="popup center">
        <h1 class="title">綁定成功</h1>
        <div class="inform-detail">
          <div class="margin-bottom-28 margin-top-28">
            <div class="flex-container space-between flex-center">
              <span>付款方式</span>
            </div>
            <hr class="gray-hr" />
          </div>
          <div class="margin-bottom-28">
            <div class="flex-container flex-center">
              <span>卡號</span>
            </div>
            <hr class="gray-hr" />
          </div>
          <div class="margin-bottom-28">
            <div class="flex-container flex-center">
              <span>付款時間</span>
            </div>
            <hr class="gray-hr" />
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
      <center>
        <button
          type="button"
          class="capsule-button text-1250 capsule-big"
        >
          確認
        </button>
      </center>
    </div>
  </div>
</template>

<script>
export default {};
</script>
